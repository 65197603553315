import React from 'react'
import Layout from '../components/layout'
import Button from '../components/elements/Button'
import { Link } from 'gatsby'

const design = () => {
    return (
        <Layout>
            <div className="container mx-auto">

                {/* colors  */}
                <div className="flex flex-row space-x-4 my-8">
                    <div className="bg-blue-500 h-36 w-36 rounded-lg text-center text-white">Primary<br />bg-blue-500</div>
                    <div className="bg-blue-900 h-36 w-36 rounded-lg text-center text-white">Secondary<br />find new navy</div>
                    <div className="bg-gray-50 h-36 w-36 rounded-lg text-center border-2 border-gray-800">Background<br />bg-gray-50</div>
                    <div className="bg-gray-900 h-36 w-36 rounded-lg text-center text-white">Dark Background<br />bg-gray-900</div>

                </div>

                {/* typography */}
                <div className="flex flex-col space-y-4">
                    <h1>Heading 1</h1>
                    <h2>Heading 2</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>
                    <h6>Heading 6</h6>
                    <small>Small</small>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam veritatis commodi aliquam nesciunt necessitatibus eum porro, illum inventore rerum aspernatur voluptate corporis? Molestias quos corrupti aliquam iste soluta quibusdam in deleniti ut repellat distinctio, labore praesentium tempore non perspiciatis dolorem veniam voluptas error delectus, maxime architecto, sed dolorum odit sint. Incidunt, pariatur veritatis labore dolore facere asperiores sint quis deleniti corporis eaque et a, ad non vitae? Vero, ullam molestiae voluptatum adipisci, animi, est quisquam itaque doloremque repellendus enim cumque reiciendis consectetur natus ut tempora. At aut autem reiciendis tenetur quia dignissimos alias vitae voluptas! Ad fugiat error id corporis!</p>
                    <a href="#">This is an anchor link</a>
                    <Link to="/about#why" className='link'>This is a gatsby link</Link>
                    <p className="text-xs ">Extra Small</p>
                    <p className="text-sm">Small</p>
                    <p className="text-base">Base</p>
                    <p className="text-lg">Large</p>
                    <p className="text-xl">Extra Large</p>
                </div>

                {/* buttons  */}
                <div className="bg-gray-900 py-12">

                    <h1 id="buttons" className='mr-6 mb-4'>Buttons</h1>

                    <Button path="#" size="small">Button S</Button>
                    <Button path="#" size="small" style="secondary">Button S</Button>
                    <Button path="#" size="small" style="white">Button S</Button>
                    <Button path="#" size="small" style="link">Button S</Button>
                    <Button path="#" size="small" style="link-white">Button S</Button>
                    <div className='my-4' />
                    <Button path="#">Button M</Button>
                    <Button path="#" style="secondary">Button M</Button>
                    <Button path="#" style="white">Button M</Button>
                    <Button path="#" style="link">Button M</Button>
                    <Button path="#" style="link-white">Button M</Button>
                    <div className='my-4' />
                    <Button path="#" size="large">Button L</Button>
                    <Button path="#" size="large" style="secondary">Button L</Button>
                    <Button path="#" size="large" style="white">Button L</Button>
                    <Button path="#" size="large" style="link">Button L</Button>
                    <Button path="#" size="large" style="link-white">Button L</Button>

                </div>

                {/* Forms  */}
                <div className="grid grid-cols-4">
                    <div className=" space-y-4">
                        <h1 className="mb-4">Forms</h1>

                        <div className="">
                            <label for="text">Text</label>
                            <input name="text" type="text" />
                        </div>
                        <div className="">
                            <label for="email">Email</label>
                            <input name="email" type="email" />
                        </div>
                        <div className="">
                            <label for="password">Password</label>
                            <input name="password" type="password" />
                        </div>
                        <div className="">
                            <label for="select">Select</label>
                            <select name="select" >
                                <option>Option 1</option>
                                <option>Option 2</option>
                                <option>Option 3</option>
                            </select>
                        </div>
                        <div className="">
                            <input name="checkbox" type="checkbox" id="checkbox" />
                            <label for="password">Checkbox</label>
                        </div>
                        <div className="">
                            <input name="radio" type="radio" />
                            <label for="radio">Radio</label>
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    )
}

export default design
